<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    var map = new AMap.Map("container", {
      resizeEnable: true,
    });
    AMap.plugin("AMap.Geolocation", function() {
      var geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, //是否使用高精度定位，默认:true
        timeout: 10000, //超过10秒后停止定位，默认：5s
        buttonPosition: "RB", //定位按钮的停靠位置
        buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
      });
      map.addControl(geolocation);
      geolocation.getCurrentPosition(function(status, result) {
        if (status == "complete") {
          console.log("定位结果：" + result.position);
          console.log("定位类别：" + result.location_type);
          result.accuracy && console.log("精度：" + result.accuracy + " 米");
          console.log("是否经过偏移：" + (result.isConverted ? "是" : "否"));
        } else {
          console.log(result);
        }
      });
    });
  },
};
</script>

<style scoped>
#container {
  height: 100vh;
}
.info {
  width: 26rem;
}
</style>
